@layer modules, ui, base;
@layer ui {
  .sk-OptionTag_main__7qykx {
  display: flex;
  box-sizing: border-box;
  max-width: 100%;
  inline-size: -moz-fit-content;
  inline-size: fit-content;
  padding: var(--sk-space-4) var(--sk-space-4) var(--sk-space-4) var(--sk-space-8);
  border-radius: var(--sk-radius-12);

  font-family: var(--sk-font-families-poppins);
  font-size: var(--sk-typography-body-2-regular-font-size);
  font-weight: var(--sk-typography-body-2-regular-font-weight);
  line-height: var(--sk-typography-body-2-regular-line-height);
  gap: var(--sk-space-4);
}

.sk-OptionTag_main__7qykx:focus-visible {
  outline: 0;
  box-shadow: var(--sk-ressource-focus-ring-shadow-light-shadow-3),
    var(--sk-ressource-focus-ring-shadow-light-shadow-2), var(--sk-ressource-focus-ring-shadow-light-shadow-1);
}

.sk-OptionTag_main--read-only__iZBb4 {
  padding: var(--sk-space-4) var(--sk-space-8);
}

.sk-OptionTag_main--label__az5U3 {
  align-self: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sk-OptionTag_main--imageContainer__FMg5a {
  align-self: center;
  width: var(--sk-space-20);
  height: var(--sk-space-20);
  margin-right: var(--sk-space-4);
}

.sk-OptionTag_main--imageContainer__FMg5a > * {
  border-radius: 4px;
}

.sk-OptionTag_main--light__nWJlS {
  background-color: var(--sk-color-grey-100);
}

.sk-OptionTag_main--light__nWJlS:not(.sk-OptionTag_main--read-only__iZBb4) {
  color: var(--sk-color-black);
}

.sk-OptionTag_main--light__nWJlS.sk-OptionTag_main--read-only__iZBb4 {
  color: var(--sk-color-grey-700);
}

.sk-OptionTag_main--dark__oSAAV {
  background-color: var(--sk-color-grey-700);
}

.sk-OptionTag_main--dark__oSAAV:not(.sk-OptionTag_main--read-only__iZBb4) {
  color: var(--sk-color-white);
}

.sk-OptionTag_main--dark__oSAAV.sk-OptionTag_main--read-only__iZBb4 {
  color: var(--sk-color-grey-300);
}

}
